import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, signOut } from "firebase/auth"
import { getFirestore, enableIndexedDbPersistence, doc, setDoc, addDoc, collection, serverTimestamp, onSnapshot, query, orderBy, limit, updateDoc, where, startAfter, endAt, getDocs, getDoc } from "firebase/firestore"
import { checkAuthState, enableElems, disableElems, validateForm } from '../helpers.js'
import { renderTracks } from '../templates.js'
//import { connectFirestoreEmulator } from "firebase/firestore";
//import { connectAuthEmulator } from "firebase/auth";
import {enter, leave} from 'el-transition'


const firebaseConfig = {
    apiKey: "AIzaSyC7yBVgDkSCqLsr4CqMEXVC-S9BIAGeAoo",
    authDomain: "auth.prosodie.app",
    projectId: "prosodie-26d82",
    storageBucket: "prosodie-26d82.appspot.com",
    messagingSenderId: "778139848528",
    appId: "1:778139848528:web:98961db419ce61725b6655"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
//connectAuthEmulator(auth, "http://localhost:9099");
const db = getFirestore(app)
//connectFirestoreEmulator(db, 'localhost', 8080);

// assigning to a global var so that we can refer to it from other controllers. Is there any other way to communicate between controllers?
window.auth = auth

export default class extends Controller {
    static targets = ['btnImport', 'sourceUrl', 'frmImportTrack', 'stepText', 'stepLabel', 'stepDotOne', 'stepDotTwo', 'stepDotThree', 'frameImportArticle', 'copyIcon', 'copyText', 'playButton', 'pauseIcon', 'playIcon', 'systemPlayButton', 'trackTitle', 'trackExcerpt', 'updateTitleTick', 'updateExcerptTick',
    'frameRoot', 'tabs', 'embedIcon', 'embedText', 'notify', 'notifyText', 'creditsDisplay', 'createTrackLogo']

    static values = {
        isPlaying: {type: Boolean, default: false}, 
        playingIndex: {type: Number, default: 0}
    }

    static currentTrack = null
    static trackListener = null
    static synthesisListener = null
    static updateTrackHeaderTimeout = null
    static cursors = []
    static debounceTimeout = null
    static checkCreditsTimeout = null

    initialize() {
        enableIndexedDbPersistence(db)
        .catch((err) => {})
    }

    connect() {
        checkAuthState(auth)
        // see https://leastbad.com/stimulus-power-move
        this.element[this.identifier] = this
        this.creditsObserver()
    }

    logOut() {
        signOut(auth)
        .then(() => {

        })
        .catch(error => {
            console.log(error)
        })
    }

    openSlowly(event) {
        clearTimeout(this.debounceTimeout)
        

        let displayText = ''
        if (event.params.titleText) {
          displayText= [`<p class="text-sm font-medium text-gray-900">${event.params.titleText}</p>`]
        } else {
          displayText = [`<p class="text-sm font-medium text-gray-900">${event.currentTarget.innerHTML}</p>`]
        }

        let desc = event.currentTarget.getAttribute('aria-description')
        if (desc) {
            desc.split(';').forEach((line, i) => {
                displayText.push(`<p class="mt-1 text-sm text-gray-500">${line}.</p>`)
            })
        }
        
        this.notifyTextTarget.innerHTML = displayText.join('')

        this.debounceTimeout = setTimeout(() => {
          enter(this.notifyTarget)
        }, 300)
    }
      
    closeSlowly(event) {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          leave(this.notifyTarget)
        }, 1000)
      }

    selectTab(event) {
        const selectedIndex = parseInt(event.params.tabIndex)
        this.tabsTargets.forEach((tab, i) => {
            if (i == selectedIndex) {
                tab.classList.add('border-sky-500', 'text-gray-900')
                tab.classList.remove('border-transparent', 'text-gray-500', 'hover:border-gray-300', 'hover:text-gray-700')
            } else {
                tab.classList.remove('border-sky-500', 'text-gray-900')
                tab.classList.add('border-transparent', 'text-gray-500', 'hover:border-gray-300', 'hover:text-gray-700')
            }
        })
    }

    showImportProgress(doc) {
        const stepOneDesc = 'Enter link to an article accessible from the internet.'  
        const stepTwoDesc = 'Extracting readable text content. This may take a minute or more for long articles.'
        const stepThreeDesc = 'Successfully imported article. Opening speech editor...'

        const track = doc.data()
        switch (track.status) {
            case 'queued':
                this.currentTrack = null
                this.stepTextTarget.classList.add('animate-pulse')
                this.stepLabelTarget.innerHTML = 'Process'
                this.stepTextTarget.innerHTML = stepTwoDesc
                this.stepDotOneTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepOneDesc}</span>
                    </span>
                `
                this.stepDotTwoTarget.innerHTML = `
                    <span class="relative flex items-center justify-center" aria-current="step">
                        <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                            <span class="animate-ping w-full h-full rounded-full bg-sky-200"></span>
                        </span>
                        <span class="animate-ping relative block w-2.5 h-2.5 bg-sky-600 rounded-full"></span>
                        <span class="sr-only">${stepTwoDesc}</span>
                    </span>
                `

                this.stepDotThreeTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                        <span class="sr-only">${stepThreeDesc}</span>
                    </span>
                `
                break
            case 'parsed':
                this.stepLabelTarget.innerHTML = 'Synthesise'
                this.stepTextTarget.innerHTML = stepThreeDesc
                this.stepDotOneTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepOneDesc}</span>
                    </span>
                `
                this.stepDotTwoTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepTwoDesc}/span>
                    </span>
                `
                this.stepDotThreeTarget.innerHTML = `
                    <span class="relative flex items-center justify-center" aria-current="step">
                        <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                            <span class="animate-ping w-full h-full rounded-full bg-sky-200"></span>
                        </span>
                        <span class="animate-ping relative block w-2.5 h-2.5 bg-sky-600 rounded-full"></span>
                        <span class="sr-only">${stepThreeDesc}</span>
                    </span>
                `
                this.currentTrack = doc
                setTimeout(() => {
                    this.frameImportArticleTarget.src = '/edit'
                }, 500)

                // cancel the observable
                try {
                    this.trackListener()
                } catch(err) {
                }
                this.trackListener = null
                break 
            case 'failed':
                this.stepTextTarget.classList.remove('animate-pulse')
                this.stepTextTarget.innerHTML = `<span class="text-red-700"> Failed to import article.</span> <a href="#" class="bg-gradient-to-r from-sky-100 to-sky-100 bg-growing-underline font-medium underline text-sky-700 hover:text-sky-600">Create article in speech editor.</a>`
                this.stepDotOneTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepOneDesc}</span>
                    </span>
                `
                this.stepDotTwoTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-red-400 rounded-full">
                    <span class="sr-only">${stepTwoDesc}</span>
                    </span>
                `

                this.stepDotThreeTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                        <span class="sr-only">${stepThreeDesc}</span>
                    </span>
                `
                enableElems(this.btnImportTarget, this.sourceUrlTarget)
                break
            default:
                break
        }
    }

    showCreateTrackProgress(doc) {
        this.createTrackLogoTarget.classList.add('animate-spin')

        const stepOneDesc = 'Saving speech annotations.'  
        const stepTwoDesc = 'Waiting for speech synthesis task. This may take a minute or more for long articles.'
        const stepThreeDesc = 'Successfully created audio track.'

        const track = doc.data()
        switch (track.status) {
            case 'edited':
                this.stepTextTarget.classList.add('animate-pulse')
                this.stepLabelTarget.innerHTML = 'Upload'
                this.stepTextTarget.innerHTML = stepOneDesc
                this.stepDotOneTarget.innerHTML = `
                    <span class="relative flex items-center justify-center" aria-current="step">
                        <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                            <span class="animate-ping w-full h-full rounded-full bg-sky-200"></span>
                        </span>
                        <span class="animate-ping relative block w-2.5 h-2.5 bg-sky-600 rounded-full"></span>
                        <span class="sr-only">${stepOneDesc}</span>
                    </span>
                `
                this.stepDotTwoTarget.innerHTML = `
                        <span class="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                        <span class="sr-only">${stepTwoDesc}</span>
                    </span>`
             
                this.stepDotThreeTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-gray-200 rounded-full">
                        <span class="sr-only">${stepThreeDesc}</span>
                    </span>`
                break 
            case 'scheduled':
                this.stepTextTarget.classList.add('animate-pulse')
                this.stepLabelTarget.innerHTML = 'Enqueue'
                this.stepTextTarget.innerHTML = stepTwoDesc
                this.stepDotOneTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepOneDesc}</span>
                    </span>
                `
                this.stepDotTwoTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepTwoDesc}</span>
                    </span>
                `
                this.stepDotThreeTarget.innerHTML = `
                    <span class="relative flex items-center justify-center" aria-current="step">
                        <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                            <span class="animate-ping w-full h-full rounded-full bg-sky-200"></span>
                        </span>
                        <span class="animate-ping relative block w-2.5 h-2.5 bg-sky-600 rounded-full"></span>
                        <span class="sr-only">${stepThreeDesc}</span>
                    </span>
                `
                break 
            case 'processed':
                this.stepTextTarget.classList.remove('animate-pulse')
                this.stepLabelTarget.innerHTML = 'Synthesize'
                this.stepTextTarget.innerHTML = stepThreeDesc
                this.stepDotOneTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepOneDesc}</span>
                    </span>
                `
                this.stepDotTwoTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepTwoDesc}/span>
                    </span>
                `
                this.stepDotThreeTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepThreeDesc}/span>
                    </span>
                `

                this.currentTrack = doc

                // cancel the observable
                try {
                    this.synthesisListener()
                } catch(err) {
                }
                this.synthesisListener = null

                setTimeout(() => {
                    this.frameImportArticleTarget.src = '/track'
                    this.createTrackLogoTarget.classList.remove('animate-spin')
                }, 2000)
    
                break
            case 'failed':
                this.stepTextTarget.classList.remove('animate-pulse')
                this.stepTextTarget.innerHTML = `<span class="text-red-700"> Failed to created track.</span>`
                this.stepDotOneTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                        <span class="sr-only">${stepOneDesc}</span>
                    </span>
                `
                this.stepDotTwoTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-sky-600 rounded-full">
                    <span class="sr-only">${stepTwoDesc}</span>
                    </span>
                `
                this.stepDotThreeTarget.innerHTML = `
                    <span class="block w-2.5 h-2.5 bg-red-400 rounded-full">
                        <span class="sr-only">${stepThreeDesc}/span>
                    </span>
                `
                this.createTrackLogoTarget.classList.remove('animate-spin')
                break
            default:
                break
        }

    }

    async insertTrack(event) {
        event.preventDefault()
        disableElems(this.btnImportTarget, this.sourceUrlTarget)

        if (!validateForm(this.frmImportTrackTarget)) {
            return
        }

        let url = this.sourceUrlTarget.value.trim()

        try {
            await setDoc(
                doc(db, 'users', auth.currentUser.uid),
                { },
                { merge: true }
            )

            const trackRef = await addDoc(collection(db, 'users', auth.currentUser.uid, 'tracks'), {
                created: serverTimestamp(),
                source_url: url,
                status: 'queued'
            })

            if (this.trackListener) {
                this.trackListener()
            }
    
            this.trackListener = this.subscribeLatestTrack()

        } catch (e) {
            console.error("Error adding track: ", e)
        }

    }

    async updateTrackItem(trackRef, item, value) {
        try {
            await updateDoc(trackRef, {
                [item]: value
            })
        } catch (e) {
            console.error("Error updating track: ", e)
        }
    }

    /**
     * Update or insert a track.
     *  update: when article was imported and then annotated in editor
     *  insert: when direct composing article in editor
     * Param doc: DocumentSnapshot of the track. null when composing direct in editor
     * Param delta: quill delta in JSON form.
     */
    async upsertTrack(track, delta, voice, drc) {
        // update
        if(track?.ref) {
            try {
                await updateDoc(track.ref, {
                    delta: delta,
                    voice: voice,
                    drc: drc,
                    status: 'edited'
                })
                if (this.synthesisListener) {
                    this.synthesisListener()
                }
                this.synthesisListener = this.subscribeSynthesis(track.ref)
            } catch (e) {
                console.error("Error updating track: ", e)
            }
            return
        }

        // insert
        try {
            await setDoc(
                doc(db, 'users', auth.currentUser.uid),
                { },
                { merge: true }
            )

            const trackRef = await addDoc(collection(db, 'users', auth.currentUser.uid, 'tracks'), {
                created: serverTimestamp(),
                delta: delta,
                voice: voice,
                drc: drc,
                status: 'edited'
            })

            if (this.synthesisListener) {
                this.synthesisListener()
            }
            this.synthesisListener = this.subscribeSynthesis(trackRef)

        } catch (err) {
            console.error("Error adding track: ", err)
        }

    }

    async createTrackHandler(track, delta, voice, drc) {
        this.frameImportArticleTarget.src = '/create_track'

        try {
            await this.frameImportArticleTarget.loaded
        } catch (e) {
            console.log('Error in frame reload promise', e)
        }

       
        await this.upsertTrack(track, delta, voice, drc)
        
    }

    /*
    Listens to changes in latest track document.
    @param action: function to fire on listener events. status will be passed to this function.
    @returns: observable
    */
    subscribeLatestTrack() {
        const tracksRef = collection(db, 'users', auth.currentUser.uid, 'tracks')
        const q = query(tracksRef, orderBy('created', 'desc'), limit(1))
        return onSnapshot(q, (querySnap) => {
            querySnap.forEach(doc => {
                this.showImportProgress(doc)
            })
        })
    }

    subscribeSynthesis(track) {
        return onSnapshot(track, (snap) => {
            this.showCreateTrackProgress(snap)
            this.creditsObserver()
        })
    }

    /**
     * external accessor this.console.getCurrentTrack
     */
    getCurrentTrack() {
        return this.currentTrack
    }

    unsetCurrentTrack() {
        this.currentTrack = null
    }

    copyTrackUrl(event) {
        const index = event.params.trackIndex ? parseInt(event.params.trackIndex) : 0
        const trackId = event.params.trackId ? event.params.trackId : this.currentTrack.id
        const fname = `https://prosodie.app/tracks/${auth.currentUser.uid}_${trackId}.mp3`
        navigator.clipboard.writeText(fname)
        this.copyIconTargets[index].classList.add('hidden')
        this.copyTextTargets[index].innerHTML = 'link copied'
        setTimeout(() => {
            this.copyTextTargets[index].innerHTML = ''
            this.copyIconTargets[index].classList.remove('hidden')
        }, 2000)
    }

    copyEmbedCode(event) {
        const index = event.params.trackIndex ? parseInt(event.params.trackIndex) : 0
        const trackId = event.params.trackId ? event.params.trackId : this.currentTrack.id
        const fullTrackId = `${auth.currentUser.uid}_${trackId}.mp3`
        const iframeSrc = new URL(`player/${fullTrackId}`, window.location.origin)
        const embedCode = `
            <iframe width="300" height="60" scrolling="no" frameborder="no" 
                src="${iframeSrc}"></iframe>
        `
        navigator.clipboard.writeText(embedCode)
        this.embedIconTargets[index].classList.add('hidden')
        this.embedTextTargets[index].innerHTML = 'embed code copied'
        setTimeout(() => {
            this.embedTextTargets[index].innerHTML = ''
            this.embedIconTargets[index].classList.remove('hidden')
        }, 2000)
    }

    async downloadFile(url) {
        return fetch(url)
        .then(response => {
            if (response.ok && !response.redirected) {
                return response.blob()
            }
            console.log(response)
            throw Error(response.statusText)
        })
        .then(data => {
            return data
        })
        .catch(error => {
            console.log(error)
        })
    }

    async downloadTrack(event) {
        const trackId = event.params.trackId ? event.params.trackId : this.currentTrack.id
        const fname = `https://prosodie.app/tracks/${auth.currentUser.uid}_${trackId}.mp3`
        const link = event.currentTarget

        if (link.getAttribute('download') == `${trackId}.mp3`) {
            return
        }

        link.classList.add('animate-bounce')

        try {
            const data = await this.downloadFile(fname)
            const blob = new Blob([data], {type: 'application/octet-stream'})
            link.setAttribute('href', URL.createObjectURL(blob))
            link.setAttribute('download', `${trackId}.mp3`)
            link.click()
        } catch (error) {
            console.log(error)
        }

        link.classList.remove('animate-bounce')
        
    }

    play(event) {
        const index = event.params.trackIndex ? parseInt(event.params.trackIndex) : 0
        if (!this.systemPlayButtonTargets[index].getAttribute('src')) {
            const trackId = event.params.trackId ? event.params.trackId : this.currentTrack.id
            const fname = `https://prosodie.app/tracks/${auth.currentUser.uid}_${trackId}.mp3`
            this.systemPlayButtonTargets[index].setAttribute('src', fname)
        }
        this.playingIndexValue = index
        this.isPlayingValue = !this.isPlayingValue
    }

    trackEnded(event) {
        this.isPlayingValue = false
    }

    isPlayingValueChanged() {
        // value change event is fired on controller connect. Ignore that. 
        if (!this.hasSystemPlayButtonTarget) {
            return
        }

        try {
            if (this.isPlayingValue) {
                this.systemPlayButtonTargets[this.playingIndexValue].play()
                this.playButtonTargets[this.playingIndexValue].classList.add('animate-pulse')
                this.playIconTargets[this.playingIndexValue].classList.add('hidden')
                this.pauseIconTargets[this.playingIndexValue].classList.remove('hidden')
            } else {
                this.systemPlayButtonTargets[this.playingIndexValue].pause()
                this.pauseIconTargets[this.playingIndexValue].classList.add('hidden')
                this.playIconTargets[this.playingIndexValue].classList.remove('hidden')
                this.playButtonTargets[this.playingIndexValue].classList.remove('animate-pulse')
            }
       } catch(error) {
           console.log(error)
       }
    }

    trackTitleTargetConnected(elem) {
        if (!this.currentTrack) {
            return
        }
        elem.innerHTML = this.currentTrack.data().title ? this.currentTrack.data().title: 'Track title'
    }

    trackExcerptTargetConnected(elem) {
        if (!this.currentTrack) {
            return
        }
        elem.innerHTML = this.currentTrack.data().excerpt ? this.currentTrack.data().excerpt: 'Description'
    }

    updateTrackHeaderHandler(event) {
        clearTimeout(this.updateTrackHeaderTimeout)
        const index = event.params.trackIndex ? parseInt(event.params.trackIndex) : 0

        let item
        let value
        let progressIcon
        switch (event.params.item) {
            case 'title':
                item = 'title'
                value = this.trackTitleTargets[index].innerText.replace(/[\r\n]/gm, ' ')
                progressIcon = this.updateTitleTickTargets[index]
                break
            case 'excerpt':
                item = 'excerpt'
                value = this.trackExcerptTargets[index].innerText.replace(/[\r\n]/gm, ' ')
                progressIcon = this.updateExcerptTickTargets[index]
                break
            default:
                return
        }

        const trackRef = event.params.trackId ? doc(db, 'users', auth.currentUser.uid, 'tracks', event.params.trackId) : this.currentTrack.ref
        this.updateTrackHeaderTimeout = setTimeout(async () => {
            await this.updateTrackItem(trackRef, item, value)
            enter(progressIcon)
            setTimeout(() => leave(progressIcon), 2000)
        }, 1000)
    }

    async _getTrackPages(tracksRef, params) {
        const q = query(tracksRef, ...params)
        const snaps = await getDocs(q)
        const tracks = snaps.docs
        return tracks
    }
    
    /**
     * cursors = a list of next page headers
     * e.g.; cursors = [null(p0), p1, p2, p3, p4,... ]
     * When moving forward using next-> , append next page header into the list.
     * So cursor[n] will point to next page, cursor[n-1] will point to previous page. 
     * To go back to previous page simply navigate using cursor[n-1] 
     * and then point the list header to n-1. 
     * .ie. pop the last element so that now the list has n-1 at the top
     */
    async getPlayList(direction = 'next', cursorsReset = false) {
        const tracksRef = collection(db, 'users', auth.currentUser.uid, 'tracks')
        const params = [where('status', "==", 'processed'), orderBy('created', 'desc'), limit(10)]

        // start from first record when navigating back from other pages.
        if (cursorsReset) {
            this.cursors = []
        }

        const prev = this.cursors[this.cursors.length - 2]
        const next = this.cursors[this.cursors.length - 1]

        let tracks = []
        let newNext = null
        
        switch (direction) {
            case 'next':
                if (next) {
                    params.push(startAfter(new Date(next)))
                }
                tracks = await this._getTrackPages(tracksRef, params)

                // if the 'next' cursor resulted in empty tracks, the cursor must have been the last 
                // record. No need to keep that in array.
                if (!tracks?.length) {
                    this.cursors.pop()
                    break
                }

                newNext = tracks ? tracks[tracks.length - 1]?.data()?.created?.toMillis() : null
                this.cursors.push(newNext)
                break
            case 'previous':
                if (prev) {
                    params.push(startAfter(new Date(prev)))
                }

                tracks = await this._getTrackPages(tracksRef, params)
         
                if (this.cursors.length > 1) {
                    this.cursors.pop()
                }
                break
            default:
                break

        }

        return tracks
    }

    async renderPlaylist(event) {
        event.preventDefault()
        
        const prevContent = this.frameImportArticleTarget.innerHTML

        this.frameImportArticleTarget.innerHTML = `
        <svg class="mx-auto h-12 w-auto fill-sky-600 animate-spin" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
                <g id="vinyl-svgrepo-com">
                    <path d="M13,0 C5.82044531,0 0,5.82044531 0,13 C0,20.1795547 5.82044531,26 13,26 C20.1795547,26 26,20.1795547 26,13 C26,5.82044531 20.1795547,0 13,0 Z M13,23.8333164 C7.01705469,23.8333164 2.16668359,18.9829453 2.16668359,13 C2.16668359,7.01705469 7.01705469,2.16668359 13,2.16668359 C18.9829453,2.16668359 23.8333164,7.01705469 23.8333164,13 C23.8333164,18.9829453 18.9829453,23.8333164 13,23.8333164 Z" id="Shape" fill-rule="nonzero"></path>
                    <path d="M13,9.75 C11.2046289,9.75 9.75,11.2046289 9.75,13 C9.75,14.7953711 11.2046289,16.25 13,16.25 C14.7953711,16.25 16.25,14.7953711 16.25,13 C16.25,11.2046289 14.7953711,9.75 13,9.75 Z M13,14.0833164 C12.4012383,14.0833164 11.9166836,13.5987617 11.9166836,13 C11.9166836,12.4012383 12.4012383,11.9166836 13,11.9166836 C13.5987617,11.9166836 14.0833164,12.4012383 14.0833164,13 C14.0833164,13.5987617 13.5987617,14.0833164 13,14.0833164 Z" id="Shape" fill-rule="nonzero"></path>
                    <path d="M14.0833164,5.41668359 C14.0833164,4.81837891 13.5983047,4.33336719 13,4.33336719 C8.21351172,4.33336719 4.33331641,8.2135625 4.33331641,13.0000508 C4.33331641,13.5983555 4.81832813,14.0833672 5.41663281,14.0833672 C6.0149375,14.0833672 6.49994922,13.5983555 6.49994922,13.0000508 C6.49994922,9.41017187 9.41007031,6.50005078 12.9999492,6.50005078 C13.5983047,6.5 14.0833164,6.01498828 14.0833164,5.41668359 Z" id="Path"></path>
                </g>
            </g>
          </svg>
        `

        const tracks = await this.getPlayList(event.params.direction, event.params.cursorsReset)

        if (!tracks?.length) {
            this.frameImportArticleTarget.innerHTML = prevContent
            return 
        }

        const trackRows = renderTracks(tracks)
        if (trackRows.length < 10) {
            this.frameImportArticleTarget.innerHTML = `
                <a href="/console_base" class="cursor-pointer relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg class="mx-auto h-12 w-12 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6" />
                    </svg>
                    <span class="mt-2 block text-sm font-medium text-gray-900"> Your playlist is empty. Create your first audio track by importing or composing an article. <span>
                </a>
            `
            return
        }

        const trackGrid = `
                <div class="py-12 px-4 sm:px-6 lg:px-8">
                    <div class="bg-gray-100 py-8">
                        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div class="grid grid-cols-1 gap-y-4 sm:gap-y-6 lg:gap-y-8"> 
                                ${trackRows}
                            </div>
                        </div>
                    </div>
                    <nav class="bg-gray-100 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 lg:px-8" aria-label="Pagination">
                        <div class="flex-1 flex justify-between sm:justify-end">
                            <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            data-action="console#renderPlaylist"
                            data-console-direction-param="previous"
                            > Previous </a>
                            <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            data-action="console#renderPlaylist"
                            data-console-direction-param="next"
                            > Next </a>
                        </div>
                    </nav>
                </div>    
        `
        this.unsetCurrentTrack()
        this.frameImportArticleTarget.innerHTML = trackGrid
    }

    async queryCredits() {
        if (!auth?.currentUser?.uid) {
            this.creditsObserver
            return
        }

        const docRef = doc(db, 'users', auth.currentUser.uid)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
            this.creditsDisplayTarget.innerHTML = docSnap.data().credits
            return docSnap.data().credits
        }
    }

    async creditsObserver() {
        const debounce = Math.random() * 10 * 1000
        clearTimeout(this.checkCreditsTimeout)
        this.checkCreditsTimeout = setTimeout(() => {
          this.queryCredits()
        }, debounce)
      }

}   