import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { checkAuthState, enableElems, disableElems, validateForm, hideElems, showElems } from '../helpers.js'

const firebaseConfig = {
    apiKey: "AIzaSyC7yBVgDkSCqLsr4CqMEXVC-S9BIAGeAoo",
    authDomain: "auth.prosodie.app",
    projectId: "prosodie-26d82",
    storageBucket: "prosodie-26d82.appspot.com",
    messagingSenderId: "778139848528",
    appId: "1:778139848528:web:98961db419ce61725b6655"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export default class extends Controller {
    static targets = ['freeCredits', 'error']

    static values = {
        isSignedIn: {type: Boolean, default: true}
    }   

    connect() {
        // see https://leastbad.com/stimulus-power-move
        this.element[this.identifier] = this
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.isSignedInValue = true
            } else {
                this.isSignedInValue = false
            }
        })
    }

    isSignedInValueChanged() {
        if (this.isSignedInValue) {
            this.freeCreditsTarget.classList.add('cursor-not-allowed', 'opacity-50')
        } else {
            enableElems(this.freeCreditsTarget)
            this.freeCreditsTarget.classList.remove('cursor-not-allowed', 'opacity-50')
        }
    }

    getFreeCredits(event) {
        checkAuthState(auth, null, '/sign_up')
        event.preventDefault()
    }

    checkout(event) {
        event.preventDefault()
        hideElems('invisible', this.errorTarget)
        disableElems(event.currentTarget)
        event.currentTarget.classList.add('animate-pulse')

        checkAuthState(auth, null, '/sign_up')
        
        const payload = {
            user_id: auth.currentUser?.uid,
            email: auth.currentUser?.email,
            amount: event.params?.amount,
            credits: event.params?.desc
        }

        fetch (
            '/checkout',
            {
                method: 'POST',
                mode: 'cors',
                credentials: 'same-origin',
                cache: 'no-cache',
                body: JSON.stringify(payload),
                headers: {
                  'Content-Type': 'application/json'
                }
              }
        ).then(response => {
            if (response.ok && !response.redirected) {
              return response.json()
            }
            console.log(response)
            throw Error(response.statusText)
        }).then(response => {
            const stripe = Stripe(response.key)
            return stripe.redirectToCheckout({ sessionId: response.id })
          }).catch(error => {
            showElems(this.errorTarget)
            console.log(`${new Date().toLocaleString({hour12: false})} => ${error}`)
          })
        
          event.currentTarget.classList.remove('animate-pulse')
          enableElems(event.currentTarget)
    }

}