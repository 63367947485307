import { Controller } from "@hotwired/stimulus"
import Quill from 'quill/core'
import Toolbar from 'quill/modules/toolbar'
import Snow from '../quill_mods/themes/snow'
import { Alias, Ipa, Pinyin, Yomigana, Link } from '../quill_mods/mods'
import { FontClass } from 'quill/formats/font'
import {enter, leave} from 'el-transition'
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyC7yBVgDkSCqLsr4CqMEXVC-S9BIAGeAoo",
  authDomain: "auth.prosodie.app",
  projectId: "prosodie-26d82",
  storageBucket: "prosodie-26d82.appspot.com",
  messagingSenderId: "778139848528",
  appId: "1:778139848528:web:98961db419ce61725b6655"
}

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow
})

Quill.register(Alias)
Quill.register(Ipa)
Quill.register(Pinyin)
Quill.register(Yomigana)
Quill.register(Link)

Quill.register(FontClass)
var Font = Quill.import('formats/font')
Font.whitelist = ["alt-default", "arb", "ca-ES", "cmn-CN", "da-DK", "nl-NL", "en-AU", "en-GB", "en-IN", "en-NZ", "en-ZA", "en-US", "en-GB-WLS", "fr-FR", "fr-CA", "hi-IN", "de-DE", "de-AT", "is-IS", "it-IT", "ja-JP", "ko-KR", "nb-NO", "pl-PL", "pt-BR", "pt-PT", "ro-RO", "ru-RU", "es-ES", "es-MX", "es-US", "sv-SE", "tr-TR", "cy-GB"]
Quill.register(Font, true)


export default class extends Controller {
    static targets = ['editor', 'notify', 'notifyText', 'previewPlayButton', 'previewButton',
                      'previewButtonText','pauseIcon', 'playIcon', 'voice', 'globalDrc']

    static values = {
      lastPreviewRange: String,
      lastDeltaOps: Array,
      isPlaying: {type: Boolean, default: false}
    }
    static quillEditor = null
    static debounceTimeout = null
    static currentTrackCopy = null

    createQuillAttrTag(...names) {
        const Parchment = Quill.import('parchment')
        names.forEach((name, i) => {
          const Attributor = new Parchment.Attributor.Class(name, name, {
            scope: Parchment.Scope.INLINE
          })
          Quill.register(Attributor, true)
        })
    }

    set quill(target) {
        this.quillEditor = new Quill(
          target,
          {
            modules: {
              toolbar: '#toolbar-container',
            },
            clipboard: {
                matchVisual: true
            },
            placeholder: 'Compose an article ...',
            theme: 'snow',
            formats: ['drc', 'alias', 'expletive', 'spell', 'verb', 'past', 'alt', 'digits', 'cardinal', 'fraction',
                      'font', 'pause-1x', 'pause-2x', 'pause-3x', 'silent', 'soft-3x', 'soft-2x', 'soft-1x', 
                      'loud-1x', 'loud-2x', 'slow-3x', 'slow-2x', 'slow-1x', 'fast-1x', 'fast-2x', 
                      'link', 'ipa', 'yomigana', 'pinyin', 'da-DK']
          }
        )
      }
    
      get quill() {
        return this.quillEditor
      }

      connect() {
        this.loadTrackText()
      }

      initialize() {
        this.createQuillAttrTag('drc', 'spell', 'cardinal', 'digits', 'expletive', 'fraction', 'ordinal', 'pause-1x', 'pause-2x', 'pause-3x', 'silent', 'soft-1x', 'soft-2x', 'soft-3x', 'loud-1x', 'loud-2x', 'slow-1x', 'slow-2x', 'slow-3x', 'fast-1x', 'fast-2x', 'verb', 'past', 'alt')

        this.quill = this.editorTarget

        this.quill.on('text-change', (delta, source) => {
            clearTimeout(this.debounceTimeout)
            /*
            this.debounceTimeout = setTimeout(() => {
                console.log(this.quill.getContents())
            }, 1000)
            */
        })

        /*this.quill.clipboard.addMatcher('A', function(node, delta) {
          let mode = node.getAttribute('data-mode') ?? 'link'
          console.log({mode: node.innerHTML})
          return delta.compose(new Delta().retain(delta.length(), { [mode]: node.innerHTML }));
        });*/
  
      }

      doNothing(event) {
        event.preventDefault()
      }

      openSlowly(event) {
        clearTimeout(this.debounceTimeout)
        
        let desc = event.currentTarget.getAttribute('aria-description')
        if (!desc) {
          return
        }

        let displayText = ''
        if (event.params.titleText) {
          displayText= [`<p class="text-sm font-medium text-gray-900">${event.params.titleText}</p>`]
        } else {
          displayText = [`<p class="text-sm font-medium text-gray-900">${event.currentTarget.innerHTML}</p>`]
        }

        desc.split(';').forEach((line, i) => {
          displayText.push(`<p class="mt-1 text-sm text-gray-500">${line}.</p>`)
        })
        this.notifyTextTarget.innerHTML = displayText.join('')

        this.debounceTimeout = setTimeout(() => {
          enter(this.notifyTarget)
        }, 1000)
      }
      
      closeSlowly(event) {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          leave(this.notifyTarget)
        }, 1000)
      }

      async getToken() {
        if (window.auth) {
          const auth = window.auth
        } else {
          const app = initializeApp(firebaseConfig)
          const auth = getAuth(app)
        }
        return await auth.currentUser.getIdToken(true)
      }

      async preview(event) {      
        if (this.hasNoCredit()) {
          return
        }

        const range = this.quill.getSelection()
        let index
        let length

        // user has explcitly selected some chars. Else select line including current cursor
        if (range?.length > 2) {
          index = range.index
          length = range.length
        } else {
          const lines = this.quill.getLines(range?.index)
          index = lines ? this.quill.getIndex(lines[0]) : range?.index
          length = lines ? lines[0].length() : range?.length
        }

        if (!range || length < 2) {
          if (this.isPlayingValue) {
            this.isPlayingValue = false
          } else {
            this.openSlowly(event)
            this.closeSlowly(event)
          }
          return 
        }

        // selection hasn't changed, 
        // JSON.stringify as a dirty quick comparator as js doesn't have object hash etc.
        const delta = this.quill.getContents(index, Math.min(length, 2999))
        const cursor = `${index}#${length}#${this.voiceTarget.value}#${this.globalDrcTarget.value}`
        if (cursor == this.lastPreviewRangeValue 
            && JSON.stringify(delta.ops) == JSON.stringify(this.lastDeltaOpsValue)) {
          this.isPlayingValue = !this.isPlayingValue
          return
        } else {
          this.isPlayingValue = false
        }

        this.previewButtonTarget.classList.add('animate-pulse')

        let token
        try {
          token = await this.getToken()
        } catch {

        }

        const payload = {
          ops: delta.ops,
          voice: this.voiceTarget.value,
          globalDrc: this.globalDrcTarget.value == 'on' ? true : false
        }

        fetch(
          `${PREVIEW_BASE_URL}/preview`,
          {
            method: 'POST',
            mode: 'cors',
            credentials: 'same-origin',
            cache: 'no-cache',
            body: JSON.stringify(payload),
            headers: {
              'Content-Type': 'application/json',
              'token': token
            }
          }
        ).then(response => {
          if (response.ok && !response.redirected) {
            return response.json()
          }
          console.log(response)
          throw Error(response.statusText)
        }).then(response => {
          this.lastPreviewRangeValue = cursor
          this.lastDeltaOpsValue = delta.ops
          this.previewPlayButtonTarget.setAttribute('src', response.url)
          this.isPlayingValue = true
        }).catch(error => {
          console.log(error)
        })
        this.previewButtonTarget.classList.remove('animate-pulse')
      }

      previewEnded(event) {
        this.isPlayingValue = false
      }

      isPlayingValueChanged() {
        if (this.isPlayingValue) {
          this.previewPlayButtonTarget.play()
          this.playIconTarget.classList.add('hidden')
          this.pauseIconTarget.classList.remove('hidden')
        } else {
          this.previewPlayButtonTarget.pause()
          this.pauseIconTarget.classList.add('hidden')
          this.playIconTarget.classList.remove('hidden')
        }
      }

      /**
       * If editor is opened to load an imported article text, retrive the text and populate editor content based on the current track document set on console_controller.
       */
      loadTrackText() {
        const consoleElement = document.getElementById('console')
        const doc = consoleElement.console.getCurrentTrack()
        const track = doc?.data()
        const content = []

        if (track?.title) {
          content.push(track.title)
        }

        if (track?.excerpt) {
          content.push(track.excerpt)
        }

        if (track?.text) {
          content.push(track.text)
        }

        this.quill.setText(content.join('\n'))
        this.currentTrackCopy = doc
        consoleElement.console.unsetCurrentTrack()
      }

      createTrack(event) {
        event.preventDefault()

        if (this.hasNoCredit()) {
          return 
        }

        const length = this.quill.getLength()
        if (length < 2) {
          return
        }
        const delta = this.quill.getContents(0, Math.min(100000, length))
        const consoleElement = document.getElementById('console')
        consoleElement.console.createTrackHandler(
          this.currentTrackCopy,
          JSON.stringify(delta),
          this.voiceTarget.value,
          this.globalDrcTarget.value == 'on' ? true : false
        )
      }

      hasNoCredit() {
        const consoleElement = document.getElementById('console')
        try {
          const credits = parseInt(consoleElement.console.creditsDisplayTarget.innerHTML)
          if (credits < -10000) {
            this.notifyTextTarget.innerHTML = `
                  <p class="text-sm font-medium text-gray-900">Credits: ${credits}</p>
                  <div class="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: mini/exclamation-triangle -->
                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <p class="text-sm text-yellow-700">
                          You have no credits left.
                          <a data-action="quill#gotoPricing" href="/#pricing" class="font-medium text-yellow-700 underline hover:text-yellow-600">Please top up.</a>
                        </p>
                      </div>
                    </div>
                  </div>
            `
            enter(this.notifyTarget)  
            return true
          }
        } catch(error) {
          console.log(error)
        }
      }

      gotoPricing(event) {
        // link click causes turbo frame error due to frame id mismatch . So use window redirect.
        window.location.href = '/#pricing'
      }
}
