import { onAuthStateChanged } from "firebase/auth"

function showElems(...args) {
    args.forEach((el, i) => {
      el.classList.remove('invisible')
      el.classList.remove('hidden')
    })
}

function hideElems(hideClass='invisible', ...args) {
    args.forEach((el, i) => {
      el.classList.add(hideClass)
    })
}

function disableElems(...args) {
    args.forEach((el, i) => {
      el.classList.add('cursor-not-allowed')
    })
}

function enableElems(...args) {
    args.forEach((el, i) => {
      el.classList.remove('cursor-not-allowed')
    })
}

function validateForm(el) {
  if (!el.checkValidity()) {
    el.reportValidity()
    return
  } 
  return true
}

function checkAuthState(auth, target_uri, startUrl='/sign_in') {
  onAuthStateChanged(auth, (user) => {  
    if (!user) {
      if (!window.location.href.includes('/sign_')) {
        window.location.href = startUrl
      }
    } else {
      if (target_uri) {
        window.location.href = target_uri
      }
    }
  })
}

function sanitize(url, protocols) {
  let anchor = document.createElement('a');
  anchor.href = url;
  let protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
  return protocols.indexOf(protocol) > -1;
}

export { showElems, hideElems, disableElems, enableElems, checkAuthState, validateForm, sanitize }