import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { hideElems, showElems } from '../helpers.js'

const firebaseConfig = {
    apiKey: "AIzaSyC7yBVgDkSCqLsr4CqMEXVC-S9BIAGeAoo",
    authDomain: "auth.prosodie.app",
    projectId: "prosodie-26d82",
    storageBucket: "prosodie-26d82.appspot.com",
    messagingSenderId: "778139848528",
    appId: "1:778139848528:web:98961db419ce61725b6655"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export default class extends Controller {
    static targets = ['btnSignIn', 'btnSignUp', 'btnConsole', 'slides']

    static values = {
        isSignedIn: {type: Boolean, default: true}
    }   

    connect() {
        // see https://leastbad.com/stimulus-power-move
        this.element[this.identifier] = this
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.isSignedInValue = true
            } else {
                this.isSignedInValue = false
            }
        })        
    }

    isSignedInValueChanged() {
        if (this.isSignedInValue) {
            hideElems('hidden', this.btnSignInTarget, this.btnSignUpTarget)
            showElems(this.btnConsoleTarget)
        } else {
            showElems(this.btnSignInTarget, this.btnSignUpTarget)
            hideElems('hidden', this.btnConsoleTarget)
        }
    }
}