import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, signInWithEmailAndPassword} from "firebase/auth"
import { showElems, hideElems, disableElems, enableElems, checkAuthState } from '../helpers.js'

const firebaseConfig = {
    apiKey: "AIzaSyC7yBVgDkSCqLsr4CqMEXVC-S9BIAGeAoo",
    authDomain: "auth.prosodie.app",
    projectId: "prosodie-26d82",
    storageBucket: "prosodie-26d82.appspot.com",
    messagingSenderId: "778139848528",
    appId: "1:778139848528:web:98961db419ce61725b6655"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export default class extends Controller {
  static targets = ['username', 'password', 'error', 'button']

  connect() {
    checkAuthState(auth, '/console')
  }

  initialize() {
    // classname is present during load in template.html to allow purgeCss to detect class usage
    this.buttonTarget.classList.remove('cursor-not-allowed')
  }

  doSignIn(event) {
    event.preventDefault()
    hideElems('hidden', this.errorTarget)
    disableElems(this.buttonTarget)

    let whiteSpace = /\s/gi;
    let username = this.usernameTarget.value.replace(whiteSpace, '')

    let password = this.passwordTarget.value

    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        window.location.href = '/console'
      })
      .catch((error) => {
        enableElems(this.buttonTarget)
        showElems(this.errorTarget)
      })
  }

}