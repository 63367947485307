import extend from 'extend';
import Emitter from 'quill/core/emitter';
import BaseTheme, { BaseTooltip } from './base';
import { Link as LinkBlot, Alias as AliasBlot, Ipa as IpaBlot, Pinyin as PinyinBlot, Yomigana as YomiganaBlot } from '../mods';
import { Range } from 'quill/core/selection';
import icons from 'quill/ui/icons';


const TOOLBAR_CONFIG = [
  [{ header: ['1', '2', '3', false] }],
  ['bold', 'italic', 'underline', 'alias', 'ipa', 'pinyin', 'yomigana', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean']
];

class SnowTheme extends BaseTheme {
  constructor(quill, options) {
    if (options.modules.toolbar != null && options.modules.toolbar.container == null) {
      options.modules.toolbar.container = TOOLBAR_CONFIG;
    }
    super(quill, options);
    this.quill.container.classList.add('ql-snow');
  }

  extendToolbar(toolbar) {
    toolbar.container.classList.add('ql-snow');
    this.buildButtons([].slice.call(toolbar.container.querySelectorAll('button')), icons);
    this.buildPickers([].slice.call(toolbar.container.querySelectorAll('select')), icons);
    this.tooltip = new SnowTooltip(this.quill, this.options.bounds);
  }
}
SnowTheme.DEFAULTS = extend(true, {}, BaseTheme.DEFAULTS, {
  modules: {
    toolbar: {
      handlers: {
        link: function(value) {
          if (value) {
            let range = this.quill.getSelection();
            if (range == null || range.length == 0) return;
            let preview = this.quill.getText(range);
            if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
              preview = 'mailto:' + preview;
            }
            let tooltip = this.quill.theme.tooltip;
            tooltip.edit('link', preview);
          } else {
            this.quill.format('link', false);
          }
        },
        alias: function(value) {
          if (value) {
            let range = this.quill.getSelection();
            if (range == null || range.length == 0) return;
            let preview = this.quill.getText(range);
            if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
              preview = 'mailto:' + preview;
            }
            let tooltip = this.quill.theme.tooltip;
            tooltip.edit('alias', preview);
          } else {
            this.quill.format('alias', false);
          }
        },
        ipa: function(value) {
          if (value) {
            let range = this.quill.getSelection();
            if (range == null || range.length == 0) return;
            let preview = this.quill.getText(range);
            if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
              preview = 'mailto:' + preview;
            }
            let tooltip = this.quill.theme.tooltip;
            tooltip.edit('ipa', preview);
          } else {
            this.quill.format('ipa', false);
          }
        },
        pinyin: function(value) {
          if (value) {
            let range = this.quill.getSelection();
            if (range == null || range.length == 0) return;
            let preview = this.quill.getText(range);
            if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
              preview = 'mailto:' + preview;
            }
            let tooltip = this.quill.theme.tooltip;
            tooltip.edit('pinyin', preview);
          } else {
            this.quill.format('pinyin', false);
          }
        },
        yomigana: function(value) {
          if (value) {
            let range = this.quill.getSelection();
            if (range == null || range.length == 0) return;
            let preview = this.quill.getText(range);
            if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
              preview = 'mailto:' + preview;
            }
            let tooltip = this.quill.theme.tooltip;
            tooltip.edit('yomigana', preview);
          } else {
            this.quill.format('yomigana', false);
          }
        }
      }
    }
  }
});


class SnowTooltip extends BaseTooltip {
  constructor(quill, bounds) {
    super(quill, bounds);
    this.preview = this.root.querySelector('a.ql-preview');
  }

  listen() {
    super.listen();
    this.root.querySelector('a.ql-action').addEventListener('click', (event) => {
      if (this.root.classList.contains('ql-editing')) {
        this.save();
      } else {
        switch(this.preview.getAttribute('data-mode')) {
          case 'alias':
            this.edit('alias', this.preview.textContent);
            break;
          case 'ipa':
            this.edit('ipa', this.preview.textContent);
            break;
          case 'pinyin':
            this.edit('pinyin', this.preview.textContent);
            break;
          case 'yomigana':
            this.edit('yomigana', this.preview.textContent);
            break;
          case 'link':
            this.edit('link', this.preview.textContent);
            break;
          default:
            console.log(this.preview.getAttribute('data-mode'))
        }
        
      }
      event.preventDefault();
    });
    this.root.querySelector('a.ql-remove').addEventListener('click', (event) => {
      if (this.linkRange != null) {
        let range = this.linkRange;
        this.restoreFocus();
        this.quill.formatText(range, 'link', false, Emitter.sources.USER);
        delete this.linkRange;
      } else if (this.ipaRange != null) {
        let range = this.ipaRange;
        this.restoreFocus();
        this.quill.formatText(range, 'ipa', false, Emitter.sources.USER);
        delete this.ipaRange;
      } else if (this.pinyinRange != null) {
        let range = this.pinyinRange;
        this.restoreFocus();
        this.quill.formatText(range, 'pinyin', false, Emitter.sources.USER);
        delete this.pinyinRange;
      } else if (this.yomiganaRange != null) {
        let range = this.yomiganaRange;
        this.restoreFocus();
        this.quill.formatText(range, 'yomigana', false, Emitter.sources.USER);
        delete this.yomiganaRange;
      } else if (this.aliasRange != null) {
        let range = this.aliasRange;
        this.restoreFocus();
        this.quill.formatText(range, 'alias', false, Emitter.sources.USER);
        delete this.aliasRange;
      }
      event.preventDefault();
      this.hide();
    });
    this.quill.on(Emitter.events.SELECTION_CHANGE, (range, oldRange, source) => {
      if (range == null) return;
      if (range.length === 0 && source === Emitter.sources.USER) {
        let [link, offset] = this.quill.scroll.descendant(LinkBlot, range.index);
        let [ipa, offset2] = this.quill.scroll.descendant(IpaBlot, range.index);
        let [pinyin, offset3] = this.quill.scroll.descendant(PinyinBlot, range.index);
        let [yomigana, offset4] = this.quill.scroll.descendant(YomiganaBlot, range.index);
        let [alias, offset5] = this.quill.scroll.descendant(AliasBlot, range.index);
        if (link != null) {
          this.linkRange = new Range(range.index - offset, link.length());
          let preview = LinkBlot.formats(link.domNode);
          this.preview.textContent = preview;
          this.preview.setAttribute('href', preview);
          this.preview.setAttribute('data-action', 'quill#doNothing');
          this.preview.setAttribute('data-mode', 'link')
          this.show();
          this.position(this.quill.getBounds(this.linkRange));
          return;
        } else if (ipa != null) {
          this.ipaRange = new Range(range.index - offset2, ipa.length());
          let preview = IpaBlot.formats(ipa.domNode);
          this.preview.textContent = preview;
          this.preview.setAttribute('href', preview);
          this.preview.setAttribute('data-action', 'quill#doNothing');
          this.preview.setAttribute('data-mode', 'ipa')
          this.show();
          this.position(this.quill.getBounds(this.ipaRange));
          return;
        } else if (pinyin != null) {
          this.pinyinRange = new Range(range.index - offset3, pinyin.length());
          let preview = PinyinBlot.formats(pinyin.domNode);
          this.preview.textContent = preview;
          this.preview.setAttribute('href', preview);
          this.preview.setAttribute('data-action', 'quill#doNothing');
          this.preview.setAttribute('data-mode', 'pinyin')
          this.show();
          this.position(this.quill.getBounds(this.pinyinRange));
          return;
        } else if (yomigana != null) {
          this.yomiganaRange = new Range(range.index - offset4, yomigana.length());
          let preview = YomiganaBlot.formats(yomigana.domNode);
          this.preview.textContent = preview;
          this.preview.setAttribute('href', preview);
          this.preview.setAttribute('data-action', 'quill#doNothing');
          this.preview.setAttribute('data-mode', 'yomigana')
          this.show();
          this.position(this.quill.getBounds(this.yomiganaRange));
          return;
        } else if (alias != null) {
          this.aliasRange = new Range(range.index - offset5, alias.length());
          let preview = AliasBlot.formats(alias.domNode);
          this.preview.textContent = preview;
          this.preview.setAttribute('href', preview);
          this.preview.setAttribute('data-action', 'quill#doNothing');
          this.preview.setAttribute('data-mode', 'alias')
          this.show();
          this.position(this.quill.getBounds(this.aliasRange));
          return;
        }
      } else {
        delete this.aliasRange;
        delete this.linkRange;
        delete this.ipaRange;
        delete this.pinyinRange;
        delete this.yomiganaRange;

      }
      this.hide();
    });
  }

  show() {
    super.show();
    this.root.removeAttribute('data-mode');
  }
}
SnowTooltip.TEMPLATE = [
  '<a class="ql-preview" target="_blank" href="about:blank"></a>',
  '<input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL">',
  '<a class="ql-action"></a>',
  '<a class="ql-remove"></a>'
].join('');


export default SnowTheme;
