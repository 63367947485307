/**
 * Params:
 *  tracks: an array of firestore docs.
 */
function renderTracks(tracks) {
    const gridItems = []
    tracks.forEach((track, idx) => {
        const trackTemplate = `
            <div class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm">
                <div class="flex-shrink-0 z-20">
                    <button type="button"
                    class="justify-self-center h-8 w-8 rounded-full mb-1 inline-flex items-center justify-center border border-transparent text-sm leading-4 font-medium text-sky-700 bg-sky-100 hover:bg-sky-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    aria-description="Play audio track"
                    data-console-track-id-param="${track.id}"
                    data-console-track-index-param="${idx}"
                    data-action="console#play" 
                    data-console-target="playButton">
                    <svg data-console-target="playIcon" viewBox="0 0 36 36" class="fill-sky-600 h-3 w-3">
                        <path
                        d="M33.75 16.701C34.75 17.2783 34.75 18.7217 33.75 19.299L11.25 32.2894C10.25 32.8668 9 32.1451 9 30.9904L9 5.00962C9 3.85491 10.25 3.13323 11.25 3.71058L33.75 16.701Z">
                        </path>
                    </svg>
                    <svg data-console-target="pauseIcon" viewBox="0 0 22 28" class="hidden fill-sky-600 h-3 w-3">
                        <path
                        d="M1.5 0C0.671573 0 0 0.671572 0 1.5V26.5C0 27.3284 0.671573 28 1.5 28H4.5C5.32843 28 6 27.3284 6 26.5V1.5C6 0.671573 5.32843 0 4.5 0H1.5ZM17.5 0C16.6716 0 16 0.671572 16 1.5V26.5C16 27.3284 16.6716 28 17.5 28H20.5C21.3284 28 22 27.3284 22 26.5V1.5C22 0.671573 21.3284 0 20.5 0H17.5Z"
                        fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <audio class="hidden" preload
                    data-console-target="systemPlayButton"
                    data-action="ended->console#trackEnded">
                    </audio>
                    </button>
                </div>
                <div class="min-w-0 flex-1 z-10">
                    <p class="focus:outline-none">
                    <p>
                        <span contenteditable="true" class="track-title border-b-2 border-transparent border-dotted text-sm font-medium text-gray-900"
                        data-console-target="trackTitle"
                        data-console-track-id-param="${track.id}"
                        data-console-track-index-param="${idx}"
                        data-console-item-param="title"
                        data-action="input->console#updateTrackHeaderHandler"
                        >${track.data().title?.trim() || 'Edit title'}</span>
                        <span class="hidden"
                        data-console-target="updateTitleTick"
                        data-console-enter="transform ease-out duration-300 transition" 
                        data-console-enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" 
                        data-console-enter-end="translate-y-0 opacity-100 sm:translate-x-0"
                        data-console-leave="transition ease-in duration-100" 
                        data-console-leave-start="opacity-100" 
                        data-console-leave-end="opacity-0"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 inline h-3 w-3 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                        </span>
                    </p>
                    <p>
                        <span contenteditable="true" class="track-desc border-b-2 border-transparent border-dotted text-sm text-gray-500"
                        data-console-track-id-param="${track.id}"
                        data-console-track-index-param="${idx}"
                        data-console-item-param="excerpt"
                        data-action="input->console#updateTrackHeaderHandler"
                        data-console-target="trackExcerpt">
                        ${track.data().excerpt?.trim() || 'Edit description'}
                        </span>
                        <span class="hidden"
                        data-console-target="updateExcerptTick"
                        data-console-enter="transform ease-out duration-300 transition" 
                        data-console-enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" 
                        data-console-enter-end="translate-y-0 opacity-100 sm:translate-x-0"
                        data-console-leave="transition ease-in duration-100" 
                        data-console-leave-start="opacity-100" 
                        data-console-leave-end="opacity-0"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 inline h-3 w-3 text-green-600" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                    </span>
                    </p>
                    </p>
                </div>
                <button class="z-20 flex-shrink-0 rounded-md px-2.5 py-1.5 text-sky-500 border border-gray-300 shadow-sm text-xs font-medium bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    aria-description="Copy audio track url"
                    data-console-track-id-param="${track.id}"
                    data-console-track-index-param="${idx}"
                    data-action="click->console#copyTrackUrl">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"
                    data-console-target="copyIcon">
                    <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
                    </svg>
                    <span data-console-target="copyText"></span>
                </button>
                <button class="z-20 flex-shrink-0 rounded-md px-2.5 py-1.5 text-sky-500 border border-gray-300 shadow-sm text-xs font-medium bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    aria-description="Copy audio embed code"
                    data-console-track-id-param="${track.id}"
                    data-console-track-index-param="${idx}"
                    data-action="click->console#copyEmbedCode">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor"
                    data-console-target="embedIcon">
                    <path fill-rule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    <span data-console-target="embedText"></span>
                    </svg>
                </button>
                <a class="z-20 flex-shrink-0 rounded-md px-2.5 py-1.5 text-sky-500 border border-gray-300 shadow-sm text-xs font-medium bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 cursor-pointer"
                data-console-track-id-param="${track.id}"
                data-action="console#downloadTrack">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </a>
                </div>
        `
        gridItems.push(trackTemplate)
    })
    return gridItems.join('\n')
}

export { renderTracks }