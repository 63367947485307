import Inline from 'quill/blots/inline';
import { sanitize } from '../helpers'

class ModBase extends Inline {
  static tagName = 'A';
  static SANITIZED_URL = 'about:blank';
  static PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];

  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    node.setAttribute('href', value);
    node.setAttribute('target', '_blank');
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('href')
  }

  static sanitize(url) {
    return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) return super.format(name, value);
    value = this.constructor.sanitize(value);
    this.domNode.setAttribute('href', value);
  }
}

class Ipa extends ModBase {
    static blotName = 'ipa'
    static create(value) {
        let node = super.create(value);
        node.setAttribute('data-mode', 'ipa');
        return node;
      }
}

class Pinyin extends ModBase {
    static blotName = 'pinyin'
    static create(value) {
        let node = super.create(value);
        node.setAttribute('data-mode', 'pinyin');
        return node;
      }
}

class Yomigana extends ModBase {
    static blotName = 'yomigana'
    static create(value) {
        let node = super.create(value);
        node.setAttribute('data-mode', 'yomigana');
        return node;
      }
}

class Alias extends ModBase {
    static blotName = 'alias'
    static create(value) {
        let node = super.create(value);
        node.setAttribute('data-mode', 'alias');
        return node;
      }
}

class Link extends ModBase {
  static blotName = 'link'
  static create(value) {
      let node = super.create(value);
      node.setAttribute('data-mode', 'link');
      return node;
    }
}

export { Alias, Ipa, Pinyin, Yomigana, Link };
