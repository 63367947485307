import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"
import { showElems, hideElems, disableElems, enableElems, checkAuthState, validateForm } from '../helpers.js'
//import { connectFirestoreEmulator } from "firebase/firestore";
//import { connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC7yBVgDkSCqLsr4CqMEXVC-S9BIAGeAoo",
  authDomain: "auth.prosodie.app",
  projectId: "prosodie-26d82",
  storageBucket: "prosodie-26d82.appspot.com",
  messagingSenderId: "778139848528",
  appId: "1:778139848528:web:98961db419ce61725b6655"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
//connectAuthEmulator(auth, "http://localhost:9099");
//connectFirestoreEmulator(db, 'localhost', 8080);

export default class extends Controller {
  static targets = ['username', 'password', 'error', 'button', 'frmSignUp']

  connect() {
    checkAuthState(auth, '/console')
  }

  initialize() {
    // classname is present during load in template.html to allow purgeCss to detect class usage
    this.buttonTarget.classList.remove('cursor-not-allowed')
  }

  doSignUp(event) {
    event.preventDefault()

    hideElems('hidden', this.errorTarget)
    disableElems(this.buttonTarget)

    if (!validateForm(this.frmSignUpTarget)) {
      return
    }

    let whiteSpace = /\s/gi;
    let username = this.usernameTarget.value.replace(whiteSpace, '')

    if (!MailChecker.isValid(username)) {
      this.errorTarget.innerHTML = 'Sign up failed. Unsupported email address, try again with another email address.'
      showElems(this.errorTarget)
      enableElems(this.buttonTarget)
      return
    }

    let password = this.passwordTarget.value

    createUserWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        // not waiting for the email promise to resolve here.
        sendEmailVerification(auth.currentUser, { url: `${API_URL}/sign_in` })

        window.location.href = '/console'
      })
      .catch((error) => {
        if (error.code == 'auth/email-already-in-use') {
          signInWithEmailAndPassword(auth, username, password)
            .then((userCredential) => {
              window.location.href = '/console'
            })
            .catch((error) => {
              sendPasswordResetEmail(auth, username, { url: `${API_URL}/sign_in` })
                .then(() => { })
                .catch((error) => { })
              this.errorTarget.innerHTML = 'Sign up failed. If you already have an accoount, check your inbox for a password reset email.'
              showElems(this.errorTarget)
            })
        }
      })
    enableElems(this.buttonTarget)
  }

}