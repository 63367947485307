import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { showElems, hideElems, disableElems, enableElems } from '../helpers.js'

const firebaseConfig = {
    apiKey: "AIzaSyC7yBVgDkSCqLsr4CqMEXVC-S9BIAGeAoo",
    authDomain: "auth.prosodie.app",
    projectId: "prosodie-26d82",
    storageBucket: "prosodie-26d82.appspot.com",
    messagingSenderId: "778139848528",
    appId: "1:778139848528:web:98961db419ce61725b6655"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export default class extends Controller {
    static targets = ['username', 'alert', 'button']

    initialize() {
        // classname is present during load in template.html to allow purgeCss to detect class usage
        this.buttonTarget.classList.remove('cursor-not-allowed')
    }

      submit(event) {
        event.preventDefault()

        if (!this.usernameTarget.checkValidity()) {
          this.usernameTarget.reportValidity()
          return
        }

        
        disableElems(this.buttonTarget)
        this.alertTarget.innerHTML = ' '
        this.buttonTarget.innerHTML = 'Continue'
    
        let whiteSpace = /\s/gi;
        let username = this.usernameTarget.value.replace(whiteSpace, '')

        sendPasswordResetEmail(auth, username, {url: `${API_URL}/sign_in` })
        .then(response => {
          this.buttonTarget.innerHTML = `Done 
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          `
          enableElems(this.buttonTarget)
          this.alertTarget.innerHTML = `<span class="font-medium">Password reset link sent to ${username} </span>`
        })
        .catch(error => {
          this.alertTarget.innerHTML = '<span class="font-medium text-red-500">Error</span>'
          enableElems(this.buttonTarget)
        })
      }

}